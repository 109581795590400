<i18n>
{
	"en": {
		"noItemsText": "No pages to show."
	},
	"fi": {
		"noItemsText": "Sivuja ei löytynyt."
	},
	"sv": {
		"noItemsText": "Det finns inga sidor att visa."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="loading === true"
			class="container--narrow"
		>
			<Spinner />
		</v-container>

		<template v-else-if="currentPage">
			<!-- Render current page -->
			<template v-if="currentPage.template === 'BasicPage'">
				<v-container
					v-if="currentPage.hero_image"
					class="container--narrow"
				>
					<v-img
						:src="currentPage.hero_image.url"
						:alt="currentPage.hero_image.alt"
						aspect-ratio="1.628"
						cover
					/>
				</v-container>
				<v-container class="container--narrow pt-6">
					<p
						v-if="currentPage.summary"
						class="font-weight-medium"
					>
						{{ currentPage.summary }}
					</p>
					<ContentBlocks :items="currentPage.content_blocks" />
				</v-container>
			</template>

			<!-- Render list of child items -->
			<template v-else>
				<v-container class="container--narrow">
					<FilterableList
						:items="currentPage.children"
						icon-src="_icon"
						:no-items-text="$i18n.t('noItemsText')"
						@itemClick="onItemClick"
					>
						<template #title="{ item }">
							{{ item.title }}

							<v-chip
								v-if="item._status_text"
								color="primary"
								small
								class="ml-2"
							>
								{{ item._status_text }}
							</v-chip>
						</template>
					</FilterableList>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageContainer',
	data: () => ({
		loading: false,
		currentPage: null,
	}),
	computed: {
		...mapState({
			items: state => state.pages,
		}),
	},
	watch: {
		'$route': {
			handler () {
				this.currentPage = this.resolveCurrentPage()
			},
			deep: true,
		},
	},
	async mounted () {
		this.loading = true

		this.$api.Pages.doRequest()
		await this.$nextTick()
		this.currentPage = this.resolveCurrentPage()

		this.loading = false
	},
	methods: {
		resolveCurrentPage () {
			const slugs = this.$route.fullPath.split('/').filter(name => name && name !== 'pages')

			// Check if we're on the root page
			if (!slugs.length) {
				return {
					children: this.items,
				}
			}

			let searchFrom = this.items
			let match

			for (const slug of slugs) {
				match = searchFrom.find(item => item.name === slug)

				// Slug mismatch, redirect to 404
				if (typeof match !== 'object') {
					this.$router.replace({ name: 'error404' })
					return null
				}

				// Continue searching from match children
				searchFrom = match.children || []
			}

			this.$root.$emit('setHeadline', match.title)

			return match
		},

		onItemClick (item) {
			this.$router.push({
				path: `${this.$route.path}/${item.name}`,
			})
		},
	},
}
</script>
