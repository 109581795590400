<i18n>
{
	"fi": {
		"recipient": "Valitse viestin saaja",
		"recipients": {
			"district": {
				"label": "Piiri ({district})"
			},
			"party": {
				"label": "Puolue"
			}
		},
		"noDistrictEmail": "Valitettavasti piirisi yhteystietoja ei löytynyt.",
		"sendMessage": "Lähetä viesti",
		"partyInstructions": {
			"text": "Voit lähettää puolueelle viestin SDP:n sivuston kautta.",
			"buttonText": "Lähetä viesti"
		},
		"messageSent": {
			"title": "Viestisi on lähetetty",
			"text": "Piirisi toiminnanjohtaja on vastaanottanut viestisi ja käsittelee sen mahdollisimman pian."
		}
	},
	"en": {
		"recipient": "Choose message recipient",
		"recipients": {
			"district": {
				"label": "District ({district})"
			},
			"party": {
				"label": "Party"
			}
		},
		"noDistrictEmail": "The contact details of your district were not found.",
		"sendMessage": "Send message",
		"partyInstructions": {
			"text": "You can send message to the party via SDP website.",
			"buttonText": "Send message"
		},
		"messageSent": {
			"title": "Your message was sent",
			"text": "Your district director has received your message and will process it as soon as possible."
		}
	}
}
</i18n>

<template>
	<div class="message">
		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<v-container
			v-else
			class="container--narrow"
		>
			<!-- Message was sent -->
			<v-card v-if="messageForm.sent === true">
				<v-card-title>
					{{ $i18n.t('messageSent.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					{{ $i18n.t('messageSent.text') }}
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="primary"
						text
						@click="messageForm.sent = false"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>

			<!-- Send message -->
			<v-card v-else>
				<v-card-text>
					<!-- Choose recipient -->
					<v-form :disabled="loading">
						<v-radio-group
							v-model="messageRecipient"
							:label="$i18n.t('recipient')"
							class="mt-0 mb-0"
						>
							<v-radio
								v-if="user._ref_details && user._ref_details.piiri_ref"
								:label="$i18n.t('recipients.district.label', {
									district: districtName,
								})"
								value="district"
								dense
								hide-details
							/>
							<v-radio
								:label="$i18n.t('recipients.party.label')"
								value="party"
								dense
								hide-details
							/>
						</v-radio-group>
					</v-form>
					<!-- Send message for district -->
					<template v-if="messageRecipient === 'district'">
						<SchemaToForm
							v-if="districtEmail"
							v-model="messageForm.data"
							:schema="config.forms.message"
							:disabled="loading"
							:debug="false"
							@valid="messageForm.valid = true"
							@invalid="messageForm.valid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
						<v-alert
							v-else
							type="error"
						>
							{{ $i18n.t('noDistrictEmail') }}
						</v-alert>
					</template>
					<!-- Send message for party -->
					<v-alert
						v-else-if="messageRecipient === 'party'"
						type="info"
						outlined
						class="mb-0"
					>
						{{ $i18n.t('partyInstructions.text') }}
						<div class="mt-3">
							<v-btn
								href="https://www.sdp.fi/ota-yhteytta/laheta-meille-viesti/"
								target="_blank"
								color="primary"
							>
								{{ $i18n.t('partyInstructions.buttonText') }}
							</v-btn>
						</div>
					</v-alert>
				</v-card-text>

				<!-- Submit button -->
				<template v-if="messageRecipient === 'district'">
					<v-divider />
					<v-card-actions>
						<v-btn
							color="primary"
							text
							:disabled="loading === true || messageForm.valid === false"
							:loading="loading"
							@click="sendMessage"
						>
							<v-icon left>
								mdi-email
							</v-icon>
							{{ $i18n.t('sendMessage') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Message',
	data: () => ({
		ready: false,
		loading: false,
		districtEmail: null,
		messageRecipient: 'district',
		messageForm: {
			valid: false,
			data: {},
			sent: false,
		},
	}),
	computed: {
		...mapState([
			'config',
			'user',
		]),
		districtName () {
			if (
				!this.user ||
				!this.user._ref_details ||
				!this.user._ref_details.piiri_ref
			) return null

			return this.user._ref_details.piiri_ref.title
		},
	},
	async mounted () {
		this.districtEmail = await (async () => {
			const response = await this.$api.Message.doRequest()
			if (response.success === false || !response.result.body.district_email) return Promise.resolve(null)

			return Promise.resolve(response.result.body.district_email)
		})()

		this.ready = true
	},
	methods: {
		sendMessage () {
			this.loading = true

			this.$api.Message.doRequest({
				method: 'POST',
				url: 'send',
				body: this.messageForm.data,
			}).on('done', (res) => {
				if (res.body.error) return

				this.messageForm.sent = true
				this.messageForm.data = {}
				this.$store.dispatch('clearConfirmNavigation')
			}).on('finish', () => {
				this.loading = false
			})
		},
	},
}

</script>
