<i18n>
{
	"en": {
		"membershipCard": {
			"editYourDetails": "Edit your details",
			"editYourDetailsNotify": "Membership information is edited in the OmaSDP website. Please note that the edited information will be updated in the app within an hour at the latest. Click \"Proceed\" to go to the OmaSDP service.",
			"entityNumber": "Entity number"
		},
		"news": {
			"showAll": "Show all news"
		},
		"openElections": {
			"infoText": "The following elections are open to you:",
			"noItems": "You currently have no active elections",
			"title": "Elections"
		},
		"openInvoices": {
			"showAll": "Show all invoices",
			"title": "Open invoices"
		}
	},
	"fi": {
		"membershipCard": {
			"editYourDetails": "Muokkaa tietojasi",
			"editYourDetailsNotify": "Jäsentietojen muokkaaminen tapahtuu OmaSDP-verkkosivuilla. Omien tietojen muokkaaminen vaatii kirjautumisen sivuille. Voit kirjautua jäsenrekisteritunnuksillasi tai pankkitunnuksillasi.  Huomaathan, että muokatut tiedot päivittyvät sovellukseen tunnin kuluessa. Klikkaa jatka siirtyäksesi OmaSDP-sivuille.",
			"entityNumber": "Jäsennumero"
		},
		"news": {
			"showAll": "Kaikki uutiset"
		},
		"openElections": {
			"infoText": "Sinulle on avoinna seuraavat äänestykset:",
			"noItems": "Sinulle ei ole tällä hetkellä aktiivisia äänestyksiä",
			"title": "Äänestykset"
		},
		"openInvoices": {
			"showAll": "Kaikki laskut",
			"title": "Avoimet laskut"
		}
	},
	"sv": {
		"membershipCard": {
			"editYourDetails": "Redigera dina uppgifter",
			"editYourDetailsNotify": "Medlemsinformation redigeras på OmaSDP-webbplatsen. Observera att den redigerade informationen kommer att uppdateras i appen senast inom en timme. Klicka på \"Fortsätt\" för att gå till OmaSDP-sidorna.",
			"entityNumber": "Medlemsnummer"
		},
		"news": {
			"showAll": "Visa alla nyheter"
		},
		"openElections": {
			"infoText": "Följande omröstningar är öppna för dig:",
			"noItems": "Du har inga aktiva omröstningar just nu",
			"title": "Omröstningar"
		},
		"openInvoices": {
			"showAll": "Visa alla fakturor",
			"title": "Öppna fakturor"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="config._hero_image.url"
					dark
				>
					<v-card-title class="membershipCard__title">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.member_number"
						class="white--text"
					>
						{{ $i18n.t('membershipCard.entityNumber') }}: {{ user.member_number }}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="membershipCard__title">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.member_number"
						class="white--text"
					>
						{{ $i18n.t('membershipCard.entityNumber') }}: {{ user.member_number }}
					</v-card-subtitle>
				</template>
				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						color="primary"
						@click="editDetailsDialogOpen = true"
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('membershipCard.editYourDetails') }}
					</v-btn>

					<v-dialog
						v-model="editDetailsDialogOpen"
						max-width="400"
						scrollable
					>
						<v-card>
							<v-card-title>
								{{ $i18n.t('membershipCard.editYourDetails') }}
							</v-card-title>
							<v-divider />
							<v-card-text>
								{{ $i18n.t('membershipCard.editYourDetailsNotify') }}
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									text
									@click="editDetailsDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									text
									color="primary"
									href="https://oma.sdp.fi"
									target="_blank"
									@click="editDetailsDialogOpen = false"
								>
									{{ $i18n.t('general.proceed') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Open invoices -->
		<v-container
			v-if="openInvoices.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('openInvoices.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<InvoiceList
						:items="openInvoices"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						color="primary"
						:to="{ name: 'invoiceContainer' }"
					>
						<v-icon left>
							mdi-receipt
						</v-icon>
						{{ $i18n.t('openInvoices.showAll') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Open elections -->
		<v-container
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('openElections.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<p v-if="openElections.length">
						{{ $i18n.t('openElections.infoText') }}
					</p>
					<FilterableList
						:items="openElections"
						redirect-url-src="url"
						:enable-search="false"
						:no-items-text="$i18n.t('openElections.noItems')"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- News -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('routes.newsContainer.headline') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="news"
						subtitle-src="date"
						:summary-src="null"
						:meta-src="null"
						redirect-url-src="link"
						:enable-search="false"
						:max-items="3"
						@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="primary"
						text
						:to="{ name: 'newsContainer' }"
					>
						<v-icon left>
							mdi-bullhorn
						</v-icon>
						{{ $i18n.t('news.showAll') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	data: () => ({
		editDetailsDialogOpen: false,
	}),
	computed: {
		...mapState([
			'config',
			'user',
			'openElections',
			'news',
		]),
		openInvoices () {
			return this.user.invoice.filter(item => item._is_paid === false)
		},
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>

<style lang="scss">

.membershipCard {
	&__title {
		@media (max-width: 600px) {
			font-size: 1em !important;
			padding-bottom: .6em;
		}
	}
}

</style>
