<i18n>
{
	"fi": {
		"profile": {
			"title": "Oma profiili",
			"nickname": {
				"title": {
					"set": "Aseta nimimerkkisi",
					"change": "Vaihda nimimerkkisi"
				},
				"info": {
					"set": "Et ole vielä asettanut nimimerkkiäsi vaalipeliin. Nimimerkkisi näytetään joukkueiden jäsenlistoilla ja tilastoissa.",
					"change": "Nimimerkkisi näytetään joukkueiden jäsenlistoilla ja tilastoissa."
				}
			},
			"avatar": {
				"add": "Lataa profiilikuva",
				"change": "Vaihda profiilikuva",
				"delete": "Poista profiilikuva"
			},
			"deleteAvatar": {
				"title": "Poista profiilikuva",
				"text": "Haluatko varmasti poistaa profiilikuvan?"
			}
		},
		"downloadStatistics": {
			"title": "Lataa tilasto"
		},
		"createTeam": {
			"title": "Luo oma joukkueesi",
			"createTeam": "Luo joukkue"
		},
		"myTeams": {
			"title": "Oma joukkue | Omat joukkueet",
			"noItemsText": {
				"member": "Et ole vielä liittynyt joukkueeseen tässä vaalipelissä. Klikkaa \"Liity joukkueeseen\" ja valitse itsellesi joukkue.",
				"candidate": "Olet ehdokkaana tai vaalipäällikkönä tässä vaalissa. Osallistu vaalipeliin luomalla oma joukkue. Varmistathan ettei tiimillesi ole jo perustettu joukkuetta."
			}
		},
		"joinTeam": {
			"title": "Liity joukkueeseen",
			"district": "Valitse piiri",
			"loadingText": "Ladataan joukkueita",
			"noItemsText": "Valitettavasti emme löytäneet joukkueita.",
			"modal": {
				"title": "Liity joukkueeseen",
				"infoText": "Olet liittymässä joukkueeseen \"{teamName}\". Haluatko varmasti jatkaa liittymistä?"
			}
		},
		"myTeam": {
			"title": "Oma joukkue"
		},
		"districts": {
			"title": "Piirit",
			"noItemsText": "Piirejä ei löytynyt."
		},
		"standings": {
			"title": "Sarjataulukko"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="loading === true"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view />

			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'contest' && item && Object.keys(item).length">
				<v-container class="container--narrow">
					<v-card>
						<v-img
							v-if="item.hero_image"
							class="white--text align-end"
							aspect-ratio="1.628"
							:src="item.hero_image.url"
							gradient="rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .6) 100%"
							dark
						>
							<v-card-title>
								{{ item.title }}
							</v-card-title>
						</v-img>
						<v-card-text>
							<FilterableList
								:items="item._details"
								subtitle-src="label"
								title-src="value"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<template v-if="item._download_links && item._download_links.length">
							<v-divider />
							<v-card-actions>
								<v-btn
									text
									color="primary"
									@click="statisticsDialogOpen = true"
								>
									<v-icon left>
										mdi-download
									</v-icon>
									{{ $i18n.t('downloadStatistics.title') }}
								</v-btn>
							</v-card-actions>
						</template>
					</v-card>
				</v-container>

				<!-- Player profile -->
				<v-container
					v-if="user._is_admin === false && user._contest_profile"
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('profile.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<FilterableList
								:items="[user._contest_profile]"
								title-src="nickname"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<div class="button-group">
								<v-btn
									color="primary"
									text
									@click="openNicknameDialog"
								>
									<v-icon left>
										mdi-pencil
									</v-icon>
									{{ $i18n.t('profile.nickname.title.change') }}
								</v-btn>
								<v-btn
									color="primary"
									text
									@click="openAvatarDialog"
								>
									<v-icon left>
										mdi-image-plus
									</v-icon>
									<template v-if="user._contest_profile.avatar">
										{{ $i18n.t('profile.avatar.change') }}
									</template>
									<template v-else>
										{{ $i18n.t('profile.avatar.add') }}
									</template>
								</v-btn>
							</div>
						</v-card-actions>
					</v-card>
				</v-container>

				<!-- Statistics -->
				<v-container
					v-if="item._statistics && Object.keys(item._statistics).length"
					class="container--narrow"
				>
					<v-row>
						<v-col
							v-if="item._statistics.top_teams"
							cols="12"
							sm="6"
						>
							<StatisticsTeams
								:title="item._statistics.top_teams.title"
								:error="item._statistics.top_teams.error"
								:info="item._statistics.top_teams.info"
								:teams="item._statistics.top_teams.items"
							>
								<template
									v-if="item._statistics.top_teams.items"
									#actions
								>
									<v-btn
										text
										color="primary"
										@click="openStandingsDialog"
									>
										<v-icon left>
											mdi-chart-bar
										</v-icon>
										{{ $i18n.t('standings.title') }}
									</v-btn>
								</template>
							</StatisticsTeams>
						</v-col>
						<v-col
							v-if="item._statistics.meetings_persons_total"
							cols="12"
							sm="6"
						>
							<StatisticsProgress
								:title="item._statistics.meetings_persons_total.title"
								:error="item._statistics.meetings_persons_total.error"
								:info="item._statistics.meetings_persons_total.info"
								:progress="item._statistics.meetings_persons_total.progress"
								:details="item._statistics.meetings_persons_total.details"
							/>
						</v-col>
					</v-row>
				</v-container>

				<!-- District list for admins -->
				<v-container
					v-if="user._is_admin === true && item._uses_districts === true"
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('districts.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<FilterableList
								:items="item._districts"
								:enable-search="item._districts.length > 10"
								icon-src="_icon"
								title-src="title"
								summary-src="_summary"
								:no-items-text="$i18n.t('districts.noItemsText')"
								@itemClick="item => $router.push({ name: 'district', params: { districtName: item.name } })"
							/>
						</v-card-text>
					</v-card>
				</v-container>

				<!-- My teams -->
				<v-container
					class="container--narrow"
				>
					<v-card-title class="pl-0">
						{{ $i18n.tc('myTeams.title', item._my_teams.length) }}
					</v-card-title>
					<v-row
						v-if="item._my_teams.length"
						class="mb-4"
					>
						<v-col
							v-for="team of item._my_teams"
							:key="team.id"
							cols="12"
							sm="6"
						>
							<v-card
								:to="
									item._uses_districts === true
										? { name: 'districtTeam', params: { districtName: team._district_name, teamName: team.name } }
										: { name: 'contestTeam', params: { teamName: team.name } }
								"
								height="100%"
							>
								<v-img
									v-if="team.hero_image"
									class="white--text align-end"
									aspect-ratio="1.628"
									:src="team.hero_image.url"
									gradient="rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .6) 100%"
									dark
								>
									<v-card-title>
										{{ team.title }}
									</v-card-title>
									<v-card-subtitle>
										{{ team._summary }}
									</v-card-subtitle>
								</v-img>
								<template v-else>
									<v-card-title>
										{{ team.title }}
									</v-card-title>
									<v-card-subtitle>
										{{ team._summary }}
									</v-card-subtitle>
								</template>
							</v-card>
						</v-col>
					</v-row>
					<v-alert
						v-else
						type="info"
					>
						<template v-if="item._member_is_candidate === true">
							{{ $i18n.t('myTeams.noItemsText.candidate') }}
						</template>
						<template v-else>
							{{ $i18n.t('myTeams.noItemsText.member') }}
						</template>
					</v-alert>
					<v-btn
						v-if="item._member_can_create_team === true"
						color="primary"
						class="mr-3"
						@click="openCreateTeamDialog"
					>
						<v-icon left>
							mdi-account-multiple-plus
						</v-icon>
						{{ $i18n.t('createTeam.createTeam') }}
					</v-btn>
					<v-btn
						color="primary"
						class="mr-3"
						@click="openJoinTeamDialog"
					>
						<v-icon left>
							mdi-account-multiple-plus
						</v-icon>
						{{ $i18n.t('joinTeam.title') }}
					</v-btn>
				</v-container>

				<!-- Dialog for downloading statistics -->
				<v-dialog
					v-model="statisticsDialogOpen"
					max-width="400"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('downloadStatistics.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<v-list>
								<v-list-item
									v-for="link of item._download_links"
									:key="link.name"
									:href="link.url"
									download
								>
									<v-list-item-icon v-if="link.icon">
										<v-icon color="primary">
											{{ link.icon }}
										</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-if="link.title">
											{{ link.title }}
										</v-list-item-title>
										<v-list-item-subtitle v-if="link.description">
											{{ link.description }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="statisticsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for setting nickname -->
				<v-dialog
					v-model="nicknameDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t(user._nickname ? 'profile.nickname.title.change' : 'profile.nickname.title.set') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							{{ $i18n.t(user._nickname ? 'profile.nickname.info.change' : 'profile.nickname.info.set') }}
							<SchemaToForm
								class="mt-6"
								ref="nicknameForm"
								v-model="nicknameFormData"
								:schema="config.forms.contestNickname"
								:disabled="nicknameSaveLoading"
								:debug="false"
								@valid="nicknameFormValid = true"
								@invalid="nicknameFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="nicknameSaveLoading === true || nicknameFormValid === false"
								:loading="nicknameSaveLoading === true"
								@click="saveNickname"
							>
								{{ $i18n.t('general.save') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for modifying avatar -->
				<v-dialog
					v-model="avatarDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							<template v-if="user._contest_profile.avatar">
								{{ $i18n.t('profile.avatar.change') }}
							</template>
							<template v-else>
								{{ $i18n.t('profile.avatar.add') }}
							</template>
						</v-card-title>
						<v-divider />
						<v-card-text>
							<v-img
								v-if="user._contest_profile.avatar"
								aspect-ratio="1.628"
								:src="user._contest_profile.avatar.url"
								dark
								class="mb-4"
							>
								<v-app-bar
									flat
									color="rgba(0, 0, 0, 0)"
								>
									<v-spacer />
									<v-btn
										color="primary"
										fab
										small
										@click="deleteAvatarDialogOpen = true"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-app-bar>
							</v-img>
							<SchemaToForm
								ref="avatarForm"
								v-model="avatarFormData"
								:schema="config.forms.contestAvatar"
								:disabled="avatarSaveLoading"
								:debug="false"
								@valid="avatarFormValid = true"
								@invalid="avatarFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="avatarSaveLoading === true"
								@click="avatarDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="avatarSaveLoading === true || avatarFormValid === false"
								:loading="avatarSaveLoading"
								@click="saveAvatar"
							>
								{{ $i18n.t('general.save') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for deleting avatar -->
				<v-dialog
					v-model="deleteAvatarDialogOpen"
					max-width="300"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('profile.deleteAvatar.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							{{ $i18n.t('profile.deleteAvatar.text') }}
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="avatarSaveLoading === true"
								@click="deleteAvatarDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="avatarSaveLoading === true"
								:loading="avatarSaveLoading"
								@click="deleteAvatar"
							>
								{{ $i18n.t('general.delete') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for creating a new team -->
				<v-dialog
					v-model="createTeamDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('createTeam.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<SchemaToForm
								ref="createTeamForm"
								v-model="createTeamFormData"
								:schema="config.forms.team"
								:disabled="createTeamSaveLoading"
								:debug="false"
								@valid="createTeamFormValid = true"
								@invalid="createTeamFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="createTeamSaveLoading === true"
								@click="createTeamDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="createTeamSaveLoading === true || createTeamFormValid === false"
								:loading="createTeamSaveLoading"
								@click="createTeam"
							>
								<v-icon left>
									mdi-account-multiple-plus
								</v-icon>
								{{ $i18n.t('createTeam.createTeam') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for joining team -->
				<v-dialog
					v-model="joinTeamDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('joinTeam.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<v-form v-if="item._districts && item._districts.length">
								<v-select
									v-if="item._uses_districts === true"
									v-model="availableTeamsSelectedDistrict"
									:label="$i18n.t('joinTeam.district')"
									:items="item._districts"
									item-value="id"
									item-text="title"
									hide-details
									@change="loadAvailableTeams"
								/>
							</v-form>
							<Spinner
								v-if="loadingAvailableTeams === true"
								:text="$i18n.t('joinTeam.loadingText')"
								class="mt-6"
							/>
							<FilterableList
								v-else
								:items="availableTeams"
								:enable-search="availableTeams.length > 10"
								class="mt-3"
								icon-src="_icon"
								title-src="title"
								summary-src="_district_title"
								:no-items-text="$i18n.t('joinTeam.noItemsText')"
								@itemClick="openJoinTeamConfirmDialog"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="joinTeamSaveLoading === true"
								:loading="joinTeamSaveLoading === true"
								@click="joinTeamDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for confirming team join -->
				<v-dialog
					v-model="joinTeamConfirmDialogOpen"
					max-width="300"
					persistent
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('joinTeam.modal.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							{{ $i18n.t('joinTeam.modal.infoText', { teamName: joinTeamSelected.title }) }}
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="joinTeamSaveLoading === true"
								@click="joinTeamConfirmDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="joinTeamSaveLoading === true"
								:loading="joinTeamSaveLoading === true"
								@click="joinTeam(joinTeamSelected)"
							>
								{{ $i18n.t('general.yes') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for standings -->
				<v-dialog
					v-model="standingsDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('standings.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<Spinner v-if="standingsLoading === true" />
							<FilterableList
								v-else
								:items="standings"
								icon-src="_icon"
								icon-color-src="_icon_color"
								subtitle-src="_list_summary"
								title-src="title"
								summary-src="_meetings_persons_total_summary"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="standingsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import StatisticsTeams from '@/components/statistics/StatisticsTeams'
import StatisticsProgress from '@/components/statistics/StatisticsProgress'

export default {
	name: 'Contest',
	components: {
		StatisticsTeams,
		StatisticsProgress,
	},
	data: () => ({
		loading: false,
		loadingAvailableTeams: true,
		item: {},
		availableTeamsSelectedDistrict: null,
		availableTeams: [],
		avatarDialogOpen: false,
		avatarFormData: {},
		avatarFormValid: false,
		avatarSaveLoading: false,
		deleteAvatarDialogOpen: false,
		nicknameDialogOpen: false,
		nicknameFormData: {},
		nicknameFormValid: false,
		nicknameSaveLoading: false,
		joinTeamDialogOpen: false,
		joinTeamSelected: {},
		joinTeamSaveLoading: false,
		joinTeamConfirmDialogOpen: false,
		createTeamDialogOpen: false,
		createTeamFormData: {},
		createTeamFormValid: false,
		createTeamSaveLoading: false,
		standingsDialogOpen: false,
		standingsLoading: false,
		standings: [],
		statisticsDialogOpen: false,
	}),
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
		}),
	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			handler (val) {
				if (
					val._is_admin === false &&
					val._contest_profile &&
					val._contest_profile.has_nickname_set === false
				) {
					this.openNicknameDialog()
					return
				}

				this.nicknameDialogOpen = false
			},
		},
	},
	async mounted () {
		if (this.$route.name === 'contest') {
			await this.loadItem()
			await this.$nextTick()
			if (!this.item) return

			this.$root.$emit('setHeadline', this.item.title)
		}
	},
	methods: {
		// Load requested item from the store
		loadItem (silent = false) {
			return new Promise((resolve, reject) => {
				if (silent === false) this.loading = true

				this.$api.Contests.doRequest({
					method: 'GET',
					url: this.$route.params.contestName,
				}).on('done', (res) => {
					if (!res.body.contest) {
						this.$router.replace({ name: 'error404' })
						return reject()
					}

					this.item = res.body.contest
					return resolve(this.item)
				}).on('finish', () => {
					this.loading = false
				})
			})
		},

		async openAvatarDialog () {
			this.avatarFormData = {}
			this.avatarDialogOpen = true

			await this.$nextTick()

			this.$refs.avatarForm.$children[0].resetValidation()
		},

		saveAvatar () {
			this.avatarSaveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				url: 'set-avatar',
				body: this.avatarFormData,
			}).on('done', (res) => {
				if (res.body.error || !res.body.memberData) return

				this.avatarFormData = {}
				this.$refs.avatarForm.$children[0].resetValidation()
				this.avatarDialogOpen = false
			}).on('finish', () => {
				this.avatarSaveLoading = false
			})
		},

		deleteAvatar () {
			this.avatarSaveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				url: 'delete-avatar',
			}).on('done', (res) => {
				if (res.body.error || !res.body.memberData) return

				this.deleteAvatarDialogOpen = false
				this.avatarDialogOpen = false
			}).on('finish', () => {
				this.avatarSaveLoading = false
			})
		},

		openNicknameDialog () {
			this.nicknameFormData.nickname = this.user._contest_profile.nickname || this.user._full_name
			this.nicknameDialogOpen = true
		},

		saveNickname () {
			this.nicknameSaveLoading = true

			this.$api.Me.doRequest({
				url: 'set-contest-nickname',
				method: 'POST',
				body: this.nicknameFormData,
			}).on('finish', () => {
				this.nicknameSaveLoading = false
			})
		},

		loadAvailableTeams () {
			return new Promise((resolve, reject) => {
				this.loadingAvailableTeams = true

				this.$api.Contests.doRequest({
					method: 'GET',
					url: this.$route.params.contestName,
					query: {
						action: 'get-available-teams',
						...(() => {
							if (
								!this.availableTeamsSelectedDistrict ||
								this.item._uses_districts === false
							) return {}

							return {
								'district-id': this.availableTeamsSelectedDistrict,
							}
						})(),
					},
				}).on('done', (res) => {
					if (!res.body.teams) {
						this.availableTeams = []
						return reject()
					}

					this.availableTeams = res.body.teams
					return resolve(this.availableTeams)
				}).on('finish', () => {
					this.loadingAvailableTeams = false
				})
			})
		},

		async openJoinTeamDialog () {
			if (this.item._uses_districts === true) {
				// Pre-select district in join team dialog
				const districtResult = this.item._districts.find(item => [this.user._district_id, this.user._constituency_id].includes(item.register_id))
				if (districtResult && typeof districtResult === 'object') this.availableTeamsSelectedDistrict = districtResult.id
			}

			await this.$nextTick()

			this.loadAvailableTeams()
			this.joinTeamDialogOpen = true
		},

		openJoinTeamConfirmDialog (item = {}) {
			this.joinTeamSelected = item
			this.joinTeamConfirmDialogOpen = true
		},

		joinTeam (team = {}) {
			if (!team || typeof team !== 'object' || !team.id) return

			this.joinTeamSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: (() => {
					if (this.item._uses_districts === true) {
						return `${team._contest_name}/${team._district_name}/${team.name}`
					}

					return `${team._contest_name}/${team.name}`
				})(),
				query: {
					action: 'join',
				},
			}).on('done', async (res) => {
				if (res.body.error) return

				this.joinTeamConfirmDialogOpen = false
				this.joinTeamDialogOpen = false

				if (res.body.team === null) return

				this.$router.push(
					this.item._uses_districts === true
						? { name: 'districtTeam', params: { districtName: res.body.team._district_name, teamName: res.body.team.name } }
						:	{ name: 'contestTeam', params: { teamName: res.body.team.name } }
				)
			}).on('finish', () => {
				this.joinTeamSaveLoading = false
			})
		},

		async openCreateTeamDialog () {
			this.createTeamFormData = {}
			this.createTeamDialogOpen = true

			await this.$nextTick()

			this.$refs.createTeamForm.$children[0].resetValidation()
		},

		createTeam () {
			this.createTeamSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'POST',
				url: this.item.name,
				query: {
					action: 'create-team',
				},
				body: this.createTeamFormData,
			}).on('done', async (res) => {
				if (res.body.error) return

				if (res.body.contest) {
					this.item = res.body.contest
				}

				if (res.body.team) {
					this.createTeamDialogOpen = false

					this.$router.push(
						this.item._uses_districts === true
							? { name: 'districtTeam', params: { districtName: res.body.team._district_name, teamName: res.body.team.name } }
							:	{ name: 'contestTeam', params: { teamName: res.body.team.name } }
					)
				}
			}).on('finish', () => {
				this.createTeamSaveLoading = false
			})
		},

		openStandingsDialog () {
			this.standingsDialogOpen = true
			this.standingsLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: this.item.name,
				query: {
					action: 'get-standings',
				},
			}).on('done', (res) => {
				if (res.body.standings) {
					this.standings = res.body.standings
				}
			}).on('finish', () => {
				this.standingsLoading = false
			})
		},
	},
}
</script>
