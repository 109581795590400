<template>
	<FilterableList
		:items="items"
		subtitle-src="_list_summary"
		title-src="_list_title"
		summary-src="description"
		:enable-click="false"
		:enable-search="false"
		:no-items-text="noItemsText"
	>
		<template #summary="slotProps">
			<span v-html="slotProps.item.description" />
			<div
				v-if="slotProps.item.method"
				class="mt-2"
			>
				<v-chip
					v-if="slotProps.item.method"
					color="primary"
					x-small
				>
					{{ slotProps.item.method.title }}
				</v-chip>
			</div>
		</template>
	</FilterableList>
</template>

<script>

export default {
	name: 'MeetingList',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},

		noItemsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},
	},
}

</script>
