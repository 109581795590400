<i18n>
{
	"fi": {
		"noItemsText": "Tällä hetkellä yhtäkään vaalipeliä ei ole käynnissä."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="loading === true"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'contestContainer'">
				<v-container class="container--narrow">
					<FilterableList
						:items="contests"
						icon-src="_icon"
						summary-src="_period_of_validity"
						:no-items-text="$i18n.t('noItemsText')"
						:enable-search="false"
						@itemClick="item => $router.push({ name: 'contest', params: { contestName: item.name } })"
					/>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ContestContainer',
	data: () => ({
		loading: false,
	}),
	computed: {
		...mapState({
			user: state => state.user,
			contests: state => state.contests,
		}),
	},
	async mounted () {
		if (this.$route.name === 'contestContainer') {
			this.loading = true
			await this.$api.Contests.doRequest()
			await this.$nextTick()

			// If there's only one contest, we will redirect either to the contest page or team page
			if (this.contests && this.contests.length === 1) {
				this.$router.replace({
					name: 'contest',
					params: {
						contestName: this.contests[0].name,
					},
				})
			}

			this.loading = false
		}
	},
}
</script>
