<template>
	<v-card height="100%">
		<v-card-title v-if="title">
			{{ title }}
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-alert
				v-if="error"
				type="error"
				class="ma-0"
			>
				<span v-html="error" />
			</v-alert>
			<FilterableList
				v-else
				:items="teams"
				:enable-search="false"
				icon-src="_icon"
				title-src="title"
				summary-src="_meetings_persons_total_summary"
				:list-props="{ dense: true }"
				@itemClick="item => $router.push(
					item._contest_uses_districts === true
						? { name: 'districtTeam', params: { districtName: item._district_name, teamName: item.name }}
						: { name: 'contestTeam', params: { teamName: item.name }}
				)"
			/>
			<span
				v-if="info && !error"
				v-html="info"
			/>
		</v-card-text>
		<template v-if="!!$slots.actions">
			<v-divider />
			<v-card-actions>
				<slot name="actions" />
			</v-card-actions>
		</template>
	</v-card>
</template>

<script>

export default {
	name: 'StatisticsTeams',
	props: {
		title: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		error: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		info: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		teams: {
			type: [Array],
			required: false,
			default () {
				return null
			},
		},
	},
}

</script>
