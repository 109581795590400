import store from '@/store/'
import fetch from '@apicase/adapter-fetch'
import { ApiService } from '@apicase/services'
import router from '@/router'
import { serialize } from 'object-to-formdata'

// Define base URL
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://' + window.location.hostname + ':' + (window.location.port > 9000 ? window.location.port.substr(1) : '8080') : ''

// Base service
const BaseService = new ApiService({
	adapter: fetch,
	url: baseUrl,
	mode: 'cors',
	credentials: 'include',
	hooks: {
		before ({ payload, next }) {
			// Attach current locale to all API calls
			payload.url = payload.url.replace(baseUrl, baseUrl + '/' + store.state.settings.locale)

			// If request method is POST, convert payload body to FormData object
			if (payload.method && payload.method == 'POST' && payload.body) {
				payload.body = serialize(
					payload.body,
					{
						allowEmptyArrays: true,
					}
				)
			}

			next(payload)
		},
		fail ({ result, next }) {
			switch (result.status) {
			// General error
			case 500: {
				store.commit('setError', result.body)

				next(result)

				break
			}

			// Method not allowed
			case 405: {
				// If response contains captcha header
				if (result.headers['x-amzn-waf-action'] === 'captcha') {
					// Store current URL
					// front/src/App.vue will redirect to this URL after
					// user has performed captcha.
					sessionStorage.setItem('captcha_return_url', window.location)

					// Redirecting to the root will cause redirection to captcha.
					window.location.href = window.location.origin
				}

				break
			}

			// Unauthorized access, redirect to SSO login
			case 401:
			case 403: {
				if (result.body.redirect_url) {
					window.location.href = result.body.redirect_url
				}

				break
			}

			// Page not found
			case 404: {
				router.replace({ name: 'error404' })

				next(result)

				break
			}

			// Bad request
			case 400: {
				store.commit('setError', result.body)

				next(result)

				break
			}

			default: {
				console.error('API: Unknown error')
				console.error(result)

				next(result)

				break
			}
			}
		},
		error ({ result }) {
			console.error('API ERROR:')
			console.error(result)
		},
	},
}).on('finish', res => {
	// Show errors and messages
	if (res && res.body) {
		if (res.body.error) {
			store.dispatch('setNotifyError', res.body.error)
		} else if (res.body.message) {
			store.dispatch('setNotifyMessage', res.body.message)
		}
	}
})

// Endpoint: Config
const Config = BaseService.extend({
	url: 'config/',
}).on('done', res => {
	// Clear user data
	store.commit('setConfig', res.body)
})

// Endpoint: Logout
const Logout = BaseService.extend({
	url: 'logout/',
}).on('done', res => {
	// Clear user data
	store.dispatch('clearData')

	if (res.body.redirect_url) {
		window.location.href = res.body.redirect_url
	}
})

// Endpoint: Me
const Me = BaseService.extend({
	url: 'me/',
}).on('done', res => {
	// If response contains member data, save it
	if (res.body.memberData) store.commit('setUser', res.body.memberData)
	if (res.body.open_elections) store.commit('setOpenElections', res.body.open_elections)
})

// Endpoint: News
const News = BaseService.extend({
	url: 'news/',
}).on('done', res => {
	// Save to store
	if (res.body.items) {
		store.commit('setNews', res.body.items)
	}
})

// Endpoint: Benefits
const Benefits = BaseService.extend({
	url: 'benefits/',
}).on('done', res => {
	// Save to store
	if (res.body.items) {
		store.commit('setBenefits', res.body.items)
	}
})

// Endpoint: Events
const Events = BaseService.extend({
	url: 'events/',
}).on('done', res => {
	// Save to store
	if (res.body.items) {
		store.commit('setEvents', res.body.items)
	}
})

// Endpoint: Pages
const Pages = BaseService.extend({
	url: 'pages/',
}).on('done', res => {
	// Save to store
	if (res.body.items) {
		store.commit('setPages', res.body.items)
	}
})

// Endpoint: Invoice
const Invoice = BaseService.extend({
	url: 'invoice/',
}).on('done', res => {
	// If response contains member data, save it
	if (res.body.memberData) {
		store.commit('setUser', res.body.memberData)
	}
})

// Endpoint: Contests
const Contests = BaseService.extend({
	url: 'contests/',
}).on('done', res => {
	// If response contains member data, save it
	if (res.body.contests) {
		store.commit('setContests', res.body.contests)
	}
})

// Endpoint: Message
const Message = BaseService.extend({
	url: 'message/',
})

// Define API object
const api = {
	BaseService,
	Config,
	Logout,
	Me,
	News,
	Benefits,
	Events,
	Pages,
	Invoice,
	Contests,
	Message,
}

// Export Vue plugin
export default {
	...api,
	install (Vue) {
		Vue.prototype.$api = api
	},
}
