<template>
	<v-card height="100%">
		<v-card-title v-if="title">
			{{ title }}
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-alert
				v-if="error"
				type="error"
				class="ma-0"
			>
				<span v-html="error" />
			</v-alert>
			<span
				v-else
				class="position_in_standings"
			>
				<span class="position_in_standings__value">
					{{ value }}
				</span>
			</span>
		</v-card-text>
		<template v-if="info && !error">
			<v-card-text v-html="info" />
		</template>
		<template v-if="!!$slots.actions">
			<v-divider />
			<v-card-actions>
				<slot name="actions" />
			</v-card-actions>
		</template>
	</v-card>
</template>

<script>

export default {
	name: 'StatisticsPositionInStandings',
	props: {
		title: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		error: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		info: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		value: {
			type: [Number],
			required: false,
			default () {
				return null
			},
		},
	},
}

</script>

<style lang="scss" scoped>

.position_in_standings {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 1.5em;
	height: 1.5em;
	font-size: 3em;
	border: solid .2em var(--v-primary-base);
	border-radius: 100%;

	&__value {
		display: inline-block;
		line-height: 1;
		font-weight: bold;
		color: var(--v-primary-base);
		transform: translateY(-0.07em);
	}
}

</style>
