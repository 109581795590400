import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#f54b4b',
				secondary: '#f5c8c3',
				accent: '#be0a37',
				error: '#f54b4b',
				info: '#f54b4b',
				success: '#78c8aa',
				warning: '#ffaa46',
			},
			dark: {
				primary: '#f54b4b',
				secondary: '#f5c8c3',
				accent: '#be0a37',
				error: '#f54b4b',
				info: '#f54b4b',
				success: '#78c8aa',
				warning: '#ffaa46',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
})
