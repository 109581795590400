<i18n>
{
	"en": {
		"notice": "To load this element, it is required to consent to the following cookie category: {type}."
	},
	"fi": {
		"notice": "Tämän sisällön näyttäminen edellyttää seuraavan evästetyypin hyväksymistä: {type}."
	},
	"sv": {
		"notice": "För att visa det här elementet, krävs att du samtycker till följande cookie-kategori: {type}."
	}
}
</i18n>

<template>
	<div>
		<template v-if="cookieConsentEnabled === false || contentVisible === true">
			<slot />
		</template>
		<v-alert
			v-else
			type="info"
		>
			<v-row>
				<v-col class="grow">
					{{ $i18n.t('notice', { type: this.$i18n.t('cookieTypes.' + type) }) }}
				</v-col>
			</v-row>
			<v-row>
				<v-col class="grow">
					<v-btn
						outlined
						@click="$root.$emit('openCookieDialog')"
					>
						{{ $i18n.t('general.changeCookieSettings') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-alert>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import cookieTypes from '@/utils/cookieTypes'

export default {
	name: 'CookieContent',
	props: {
		type: {
			type: String,
			required: true,
			validator: (val) => {
				return cookieTypes.includes(val)
			},
		},
	},
	data: () => ({
		cookieConsentEnabled: process.env.VUE_APP_COOKIE_CONSENT === 'true',
	}),
	computed: {
		...mapState([
			'settings',
		]),

		contentVisible () {
			return this.settings.acceptedCookies.includes(this.type)
		},
	},
}
</script>
