<i18n>
{
	"en": {
		"buttons": {
			"payInvoice": "Pay invoice"
		},
		"notifications": {
			"noInvoiceRows": "Invoice has no rows",
			"paymentCanceled": "Payment was canceled",
			"paymentErrorOccured": "An error occured during the payment",
			"paymentInstructions": "If you have an unpaid invoice, you can pay it with this account number: FI52 1555 3000 109794. Check the reference number and the invoice amount in the top invoice line. If you have any challenges with this, please contact the member service. We will be happy to help."
		},
		"paymentDialog": {
			"checkingPayment": "Checking payment",
			"creatingPayment": "Creating payment",
			"instructions": "You will be redirected to Svea Payment service, where you can pay your invoice. Please don't close the browser after payment to make sure that your payment will be successfully saved.",
			"proceedToPayment": "Proceed to payment",
			"redirectInfo": "If the automatic redirection does not work, please click the button below.",
			"redirectingToPaymentService": "Redirecting to payment service"
		},
		"titles": {
			"invoiceDetails": "Invoice details",
			"invoiceRows": "Invoice rows"
		}
	},
	"fi": {
		"buttons": {
			"payInvoice": "Maksa lasku"
		},
		"notifications": {
			"noInvoiceRows": "Laskulla ei ole rivejä",
			"paymentCanceled": "Maksu peruutettiin",
			"paymentErrorOccured": "Maksussa tapahtui virhe",
			"paymentInstructions": "Mikäli sinulla on lasku maksamatta, voit maksaa sen tällä tilinumerolla: FI52 1555 3000 109794. Tarkista viitenumero ja laskun summa ylimmältä laskuriviltä. Mikäli sinulla on haasteita tämän kanssa, niin ole yhteydessä jäsenpalveluun. Autamme mielellämme."
		},
		"paymentDialog": {
			"checkingPayment": "Tarkistetaan maksutapahtumaa",
			"creatingPayment": "Luodaan maksutapahtumaa",
			"instructions": "Laskun maksaminen tapahtuu Svea Paymentin maksupalvelussa. Älä sulje selainta laskun maksamisen jälkeen, jotta maksutapahtuma kirjautuu oikein.",
			"proceedToPayment": "Siirry maksamaan",
			"redirectInfo": "Jos automaattinen uudelleenohjaus ei toimi, klikkaa alla olevaa nappia.",
			"redirectingToPaymentService": "Siirrytään maksupalveluun"
		},
		"titles": {
			"invoiceDetails": "Laskun tiedot",
			"invoiceRows": "Laskurivit"
		}
	},
	"sv": {
		"buttons": {
			"payInvoice": "Betala faktura"
		},
		"notifications": {
			"noInvoiceRows": "Fakturan har inga rader",
			"paymentCanceled": "Betalningen avbröts",
			"paymentErrorOccured": "Ett fel uppstod vid betalningen",
			"paymentInstructions": "Om du har en obetald faktura kan du betala den till detta kontonummer: FI52 1555 3000 109794. Kontrollera referensnumret och fakturabeloppet i den översta fakturaraden. Har du några prolem med detta, kontakta medlemsservicen. Vi hjälper gärna till."
		},
		"paymentDialog": {
			"checkingPayment": "Kontrollerar betalning",
			"creatingPayment": "Skapar betalning",
			"instructions": "Du omdirigeras till Svea Betaltjänst där du kan betala din faktura. Stäng inte webbläsaren efter betalning för att säkerställa att din betalning registreras.",
			"proceedToPayment": "Fortsätt till betalning",
			"redirectInfo": "Om den automatiska omdirigeringen inte fungerar, klicka på knappen nedan.",
			"redirectingToPaymentService": "Omdirigerar till betaltjänst"
		},
		"titles": {
			"invoiceDetails": "Fakturans innehåll",
			"invoiceRows": "Fakturarader"
		}
	}
}
</i18n>

<template>
	<div v-if="item">
		<v-container
			v-if="item._details"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceDetails') }}
				</v-card-title>
				<v-divider />
				<v-card-text v-if="item._details && item._details.length">
					<FilterableList
						:items="item._details"
						:enable-click="false"
						:enable-search="false"
						subtitle-src="label"
						title-src="value"
					/>
				</v-card-text>
				<!-- <v-divider />
				<v-card-actions v-if="item._is_paid === false && item._is_payable === true">
					<v-btn
						color="primary"
						text
						@click="createPaymentDialogOpen = true"
					>
						<v-icon left>
							mdi-currency-eur
						</v-icon>
						{{ $i18n.t('buttons.payInvoice') }}
					</v-btn>
					<v-dialog
						v-model="createPaymentDialogOpen"
						max-width="400"
						persistent
						scrollable
					>
						<v-card>
							<v-card-title>
								{{ $i18n.t('buttons.payInvoice') }}
							</v-card-title>
							<v-divider />
							<v-card-text>
								<Spinner
									v-if="paymentLoading === true"
									:text="paymentLoadingText"
								/>
								<template v-else>
									{{ $i18n.t('paymentDialog.instructions') }}
								</template>
								<form
									v-if="paymentFormData && Object.keys(paymentFormData).length"
									ref="paymentForm"
									method="post"
									class="paymentForm"
									:action="paymentFormData.action"
									aria-hidden="true"
								>
									<v-alert
										type="info"
										dense
										class="mt-6"
									>
										{{ $i18n.t('paymentDialog.redirectInfo') }}
									</v-alert>
									<div class="text-center">
										<v-btn
											type="submit"
											color="primary"
										>
											<v-icon left>
												mdi-arrow-right
											</v-icon>
											{{ $i18n.t('paymentDialog.proceedToPayment') }}
										</v-btn>
									</div>
									<div
										v-if="paymentFormData.fields && Object.keys(paymentFormData.fields).length"
										class="paymentForm__fields"
									>
										<div
											v-for="(value, name, index) in paymentFormData.fields"
											:key="index"
											style="display: table-row;"
										>
											<label
												for="name"
												style="display: table-cell; width: 30%;"
											>
												{{ name }}
											</label>
											<input
												:id="name"
												:name="name"
												:value="value"
												style="display: table-cell; width: 100%;"
											>
										</div>
									</div>
								</form>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									text
									:disabled="paymentLoading"
									@click="createPaymentDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									color="primary"
									text
									:disabled="paymentLoading"
									@click="createPayment"
								>
									{{ $i18n.t('paymentDialog.proceedToPayment') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog
						v-model="checkPaymentDialogOpen"
						max-width="300"
						persistent
						scrollable
					>
						<v-card>
							<v-card-text>
								<Spinner
									:text="$i18n.t('paymentDialog.checkingPayment')"
								/>
							</v-card-text>
						</v-card>
					</v-dialog>
				</v-card-actions> -->
			</v-card>
		</v-container>
		<v-container
			v-if="item._is_payable === true"
			class="container--narrow"
		>
			<v-alert
				type="info"
				class="ma-0"
			>
				{{ $i18n.t('notifications.paymentInstructions') }}
			</v-alert>
		</v-container>
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceRows') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-data-table
						:headers="item._table_headers"
						:items="item._table_rows"
						:disable-sort="true"
						:disable-pagination="true"
						:hide-default-footer="true"
						:mobile-breakpoint="400"
						:no-data-text="$i18n.t('notifications.noInvoiceRows')"
					>
						<!-- eslint-disable -->
						<template #item.total="slotProps">
						<!-- eslint-enable -->
							<span v-html="slotProps.item.total" />
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Invoice',
	data: () => ({
		item: null,
		createPaymentDialogOpen: false,
		paymentFormData: {},
		paymentLoading: false,
		paymentLoadingText: null,
		checkPaymentDialogOpen: false,
	}),
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	mounted () {
		this.loadItem()

		// Check payment status
		// if (Object.keys(this.$route.query).length) {
		// 	this.$nextTick(() => {
		// 		this.$store.commit('setBackButtonHistoryMode', false)
		// 	})

		// 	if (this.$route.query.status === 'ok') {
		// 		this.checkPayment(this.$route.query)
		// 	} else if (this.$route.query.status === 'cancel') {
		// 		this.$store.dispatch('setNotifyMessage', this.$i18n.t('notifications.paymentCanceled'))
		// 	} else if (this.$route.query.status === 'error') {
		// 		this.$store.dispatch('setNotifyError', this.$i18n.t('notifications.paymentErrorOccured'))
		// 	}

		// 	// Clear query string
		// 	this.$router.replace({'query': null})
		// }
	},
	methods: {
		// Find current page from the store
		loadItem () {
			this.item = this.items.find(item => {
				return item.id == this.$route.params.pagename
			})

			if (!this.item) {
				this.$router.replace({ name: 'error404' })
			}
		},

		// Create a new payment and redirect user to Svea Payment
		// createPayment () {
		// 	this.paymentLoading = true
		// 	this.paymentLoadingText = this.$i18n.t('paymentDialog.creatingPayment')

		// 	// Define base URL for the current page
		// 	const baseUrl = window.location.href.split(/\?|#/)[0]

		// 	// Request payment data
		// 	this.$api.Invoice.doRequest({
		// 		method: 'POST',
		// 		url: 'createpayment/',
		// 		body: {
		// 			invoice_id: this.item.id,
		// 			pmt_okreturn: baseUrl + '?status=ok',
		// 			pmt_errorreturn: baseUrl + '?status=error',
		// 			pmt_cancelreturn: baseUrl + '?status=cancel',
		// 			pmt_delayedpayreturn: baseUrl + '?status=cancel',
		// 		},
		// 	}).on('done', res => {
		// 		if (!res.body.error) {
		// 			// Populate payment form
		// 			this.paymentFormData = Object.assign({}, res.body.paymentFormData)

		// 			// Make sure that form is fully rendered and then post it
		// 			this.$nextTick(() => {
		// 				this.paymentLoadingText = this.$i18n.t('paymentDialog.redirectingToPaymentService')

		// 				if (this.$refs.paymentForm) {
		// 					this.$refs.paymentForm.submit()
		// 				}
		// 			})
		// 		} else {
		// 			this.paymentLoading = false
		// 		}
		// 	}).on('fail', () => {
		// 		this.paymentLoading = false
		// 	})
		// },

		// Check payment status
		// checkPayment (data = {}) {
		// 	this.checkPaymentDialogOpen = true

		// 	this.$api.Invoice.doRequest({
		// 		method: 'POST',
		// 		url: 'checkpayment/',
		// 		body: data,
		// 	}).on('done', () => {
		// 		// Reload invoice from the store to update the view
		// 		this.loadItem()
		// 	}).on('finish', () => {
		// 		this.checkPaymentDialogOpen = false
		// 	})
		// },
	},
}
</script>

<style lang="scss">

.paymentForm {
	&__fields {
		overflow: hidden;
		height: 0;
	}

}

</style>
