<i18n>
{
	"en": {
		"noItemsText": "No benefits to show."
	},
	"fi": {
		"noItemsText": "Jäsenetuja ei löytynyt."
	},
	"sv": {
		"noItemsText": "Det finns inga medlemsförmåner att visa."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'benefitContainer'">
			<v-container class="container--narrow px-0 py-3">
				<v-row
					no-gutters
				>
					<v-col
						v-for="item in items"
						:key="item.id"
						cols="12"
						class="pa-3"
					>
						<v-card
							:to="{ name: 'benefit', params: { pagename: item.name } }"
							:class="[
								'benefitCard',
								item.highlight === 1 ? 'yellow lighten-4' : null,
							]"
							height="100%"
						>
							<div
								v-if="item.logo"
								class="benefitCard__logoWrap"
							>
								<v-img
									class="benefitCard__logo"
									:src="item.logo.url"
									width="100%"
									height="auto"
								/>
							</div>
							<v-card-title
								v-if="item.title"
								v-html="item.title"
							/>

							<v-card-text
								v-if="item.summary"
								v-html="item.summary"
							/>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			items: state => state.benefits,
		}),
	},
	mounted () {
		this.$api.Benefits.doRequest()
	},
}
</script>

<style lang="scss" scoped>

.benefitCard {
	&__logoWrap {
		margin: 12px 12px 0 12px;
		width: 100px;

		@media (min-width: 500px) {
			float: right;
			width: 120px;
			margin: 12px;
		}
	}

	&__logo {
		width: 25%;
	}
}

</style>
