var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',_vm._b({ref:"field",attrs:{"value":_vm.valueFormatted,"readonly":"","hide-details":_vm.dialogOpen === true},on:{"click":_vm.openDialog,"click:prepend":_vm.openDialog,"click:clear":function($event){return _vm.$emit('input', null)}}},'v-text-field',_vm.$attrs,false)),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c('v-date-picker',_vm._b({attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.pickerValue),callback:function ($$v) {_vm.pickerValue=$$v},expression:"pickerValue"}},'v-date-picker',{
				showWeek: true,
				scrollable: true,
				firstDayOfWeek: 1,
				localeFirstDayOfYear: 4,
				..._vm.pickerProps,
			},false),[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogOpen = false}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('general.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveDate}},[_vm._v(" OK ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }