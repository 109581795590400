<template>
	<v-card height="100%">
		<v-card-title v-if="title">
			{{ title }}
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-alert
				v-if="error"
				type="error"
				class="ma-0"
			>
				<span v-html="error" />
			</v-alert>
			<v-row v-else>
				<v-col
					v-if="typeof progress === 'number'"
					cols="auto"
				>
					<v-progress-circular
						:rotate="360"
						:size="70"
						:width="10"
						:value="progress"
						color="primary"
					>
						{{ progress }} %
					</v-progress-circular>
				</v-col>
				<v-col
					v-if="details"
					class="pa-0"
				>
					<FilterableList
						:items="details"
						subtitle-src="title"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
						:list-props="{ dense: true, class: 'pa-0' }"
					/>
				</v-col>
			</v-row>
		</v-card-text>
		<template v-if="info && !error">
			<v-card-text v-html="info" />
		</template>
	</v-card>
</template>

<script>

export default {
	name: 'StatisticsProgress',
	props: {
		title: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		error: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		info: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
		progress: {
			type: [Number],
			required: false,
			default () {
				return null
			},
		},
		details: {
			type: [Array],
			required: false,
			default () {
				return null
			},
		},
	},
}

</script>
