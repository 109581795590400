<i18n>
{
	"fi": {
		"tabs": {
			"team": "Joukkue",
			"members": "Jäsenet",
			"achievements": "Ansiomerkit"
		},
		"editTeam": {
			"title": "Muokkaa tietoja"
		},
		"addImage": {
			"addImage": "Lataa kuva",
			"changeImage": "Vaihda kuva",
			"deleteImage": "Poista kuva"
		},
		"deleteImage": {
			"title": "Poista kuva",
			"text": "Haluatko varmasti poistaa joukkueen kuvan?"
		},
		"announcements": {
			"title": "Tiedotteet",
			"showAll": "Kaikki tiedotteet",
			"noAnnouncements": "Ei tiedotteita"
		},
		"meetings": {
			"title": "Kohtaamiset",
			"noMeetings": "Joukkue ei ole vielä kirjannut kohtaamisia",
			"addMeeting": {
				"title": "Kirjaa kohtaaminen"
			},
			"allMeetings": {
				"title": "Kaikki kohtaamiset"
			}
		},
		"standings": {
			"title": "Sarjataulukko"
		},
		"members": {
			"noMembers": "Joukkueella ei ole jäseniä",
			"noAchievements": "Jäsenellä ei ole saavutettuja tavoitteita"
		},
		"achievements": {
			"title": {
				"team": "Joukkueen ansiomerkit",
				"member": "Henkilökohtaiset ansiomerkit"
			}
		},
		"newAchievement": {
			"title": {
				"team": "Joukkueesi saavutti ansiomerkin",
				"player": "Saavutit henkilökohtaisen ansiomerkin"
			}
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="loading === true"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<!-- Output navigation if we're not on child page -->
			<template v-if="['districtTeam', 'contestTeam'].includes($route.name) && item && Object.keys(item).length">
				<v-toolbar
					dark
					flat
				>
					<v-tabs
						background-color="primary"
						v-model="currentTab"
						centered
						center-active
						show-arrows
					>
						<v-tabs-slider />
						<v-tab>
							{{ $i18n.t('tabs.team') }}
						</v-tab>
						<v-tab>
							{{ $i18n.t('tabs.members') }}
						</v-tab>
						<v-tab>
							{{ $i18n.t('tabs.achievements') }}
						</v-tab>
					</v-tabs>
				</v-toolbar>

				<v-tabs-items v-model="currentTab">
					<!-- Team -->
					<v-tab-item>
						<v-container class="container--narrow">
							<v-card>
								<v-img
									v-if="item.hero_image"
									class="white--text align-end"
									aspect-ratio="1.628"
									:src="item.hero_image.url"
									gradient="rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .6) 100%"
									dark
								>
									<v-card-title>
										{{ item.title }}
									</v-card-title>
								</v-img>
								<v-card-text>
									<FilterableList
										:items="item._details"
										subtitle-src="label"
										title-src="value"
										:enable-click="false"
										:enable-search="false"
									/>
								</v-card-text>
								<template v-if="item._user_is_leader === true">
									<v-divider />
									<v-card-actions>
										<div class="button-group">
											<v-btn
												color="primary"
												text
												@click="openEditDialog"
											>
												<v-icon left>
													mdi-pencil
												</v-icon>
												{{ $i18n.t('editTeam.title') }}
											</v-btn>
											<v-btn
												color="primary"
												text
												@click="openImageDialog"
											>
												<v-icon left>
													mdi-image-plus
												</v-icon>
												<template v-if="item.hero_image">
													{{ $i18n.t('addImage.changeImage') }}
												</template>
												<template v-else>
													{{ $i18n.t('addImage.addImage') }}
												</template>
											</v-btn>
										</div>
									</v-card-actions>
								</template>
							</v-card>
						</v-container>

						<!-- Announcements -->
						<v-container
							v-if="item.announcements"
							class="container--narrow"
						>
							<v-card>
								<v-card-title>
									{{ $i18n.t('announcements.title') }}
								</v-card-title>
								<v-divider />
								<v-card-text>
									<AnnouncementList
										:items="item.announcements"
										@itemClick="openAnnouncementDialog"
									/>
								</v-card-text>
								<v-divider />
								<v-card-actions v-if="item.announcements.length">
									<v-btn
										text
										color="primary"
										@click="openAllAnnouncementsDialog"
									>
										<v-icon left>
											mdi-bullhorn
										</v-icon>
										{{ $i18n.t('announcements.showAll') }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-container>

						<!-- Statistics -->
						<v-container
							v-if="item._statistics && Object.keys(item._statistics).length"
							class="container--narrow"
						>
							<v-row>
								<v-col
									v-if="item._statistics.meetings_persons_total"
									cols="12"
									sm="6"
								>
									<StatisticsProgress
										:title="item._statistics.meetings_persons_total.title"
										:error="item._statistics.meetings_persons_total.error"
										:progress="item._statistics.meetings_persons_total.progress"
										:details="item._statistics.meetings_persons_total.details"
										:info="item._statistics.meetings_persons_total.info"
									/>
								</v-col>
								<v-col
									v-if="item._statistics.position_in_standings"
									cols="12"
									sm="6"
								>
									<StatisticsPositionInStandings
										:title="item._statistics.position_in_standings.title"
										:error="item._statistics.position_in_standings.error"
										:value="item._statistics.position_in_standings.value"
										:info="item._statistics.position_in_standings.info"
									>
										<template
											v-if="!item._statistics.position_in_standings.error"
											#actions
										>
											<v-btn
												text
												color="primary"
												@click="openStandingsDialog"
											>
												<v-icon left>
													mdi-chart-bar
												</v-icon>
												{{ $i18n.t('standings.title') }}
											</v-btn>
										</template>
									</StatisticsPositionInStandings>
								</v-col>
							</v-row>
						</v-container>

						<!-- Meetings -->
						<v-container
							class="container--narrow"
						>
							<v-card>
								<v-card-title>
									{{ $i18n.t('meetings.title') }}
								</v-card-title>
								<v-divider />
								<v-card-text>
									<MeetingList
										:items="item.meetings"
										:no-items-text="$i18n.t('meetings.noMeetings')"
									/>
								</v-card-text>
								<template>
									<v-divider />
									<v-card-actions v-if="item._user_is_member === true || item.meetings.length">
										<div class="button-group">
											<v-btn
												v-if="item._user_is_member === true"
												color="primary"
												text
												@click="openMeetingDialog"
											>
												<v-icon left>
													mdi-comment-plus
												</v-icon>
												{{ $i18n.t('meetings.addMeeting.title') }}
											</v-btn>
											<v-btn
												v-if="item.meetings.length"
												color="primary"
												text
												@click="openAllMeetingsDialog"
											>
												<v-icon left>
													mdi-comment-multiple
												</v-icon>
												{{ $i18n.t('meetings.allMeetings.title') }}
											</v-btn>
										</div>
									</v-card-actions>
								</template>
							</v-card>
						</v-container>
					</v-tab-item>

					<!-- Members -->
					<v-tab-item>
						<v-container class="container--narrow">
							<Spinner v-if="membersLoading === true" />
							<FilterableList
								v-else
								:items="members"
								title-src="nickname"
								:enable-click="true"
								:enable-search="members.lenght > 10"
								:no-items-text="$i18n.t('members.noMembers')"
								@itemClick="item => openMemberDialog(item)"
							/>
						</v-container>
					</v-tab-item>

					<!-- Achievements -->
					<v-tab-item>
						<v-container
							v-if="item._user_is_member === true && item._my_achievements.length"
							class="container--narrow"
						>
							<v-card>
								<v-card-title>
									{{ $i18n.t('achievements.title.member') }}
								</v-card-title>
								<v-divider />
								<v-card-text>
									<template v-for="category of item._my_achievements">
										<v-subheader :key="`category-${category.id}`">
											{{ category.title }}
										</v-subheader>
										<FilterableList
											:key="`achievementList-${category.id}`"
											:items="category.items"
											title-src="title"
											summary-src="summary"
											:enable-click="false"
											:enable-search="false"
										/>
									</template>
								</v-card-text>
							</v-card>
						</v-container>
						<v-container class="container--narrow">
							<v-card>
								<v-card-title>
									{{ $i18n.t('achievements.title.team') }}
								</v-card-title>
								<v-divider />
								<v-card-text>
									<template v-for="category of item.achievements">
										<v-subheader :key="`category-${category.id}`">
											{{ category.title }}
										</v-subheader>
										<FilterableList
											:key="`achievementList-${category.id}`"
											:items="category.items"
											title-src="title"
											summary-src="summary"
											:enable-click="false"
											:enable-search="false"
										/>
									</template>
								</v-card-text>
							</v-card>
						</v-container>
					</v-tab-item>
				</v-tabs-items>

				<!-- Dialog for editing team details -->
				<v-dialog
					v-model="editDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('editTeam.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<SchemaToForm
								ref="editForm"
								v-model="editFormData"
								:schema="config.forms.team"
								:disabled="editSaveLoading"
								@valid="editFormValid = true"
								@invalid="editFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="editSaveLoading === true"
								@click="editDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="editSaveLoading === true || editFormValid === false"
								:loading="editSaveLoading"
								@click="saveTeam"
							>
								{{ $i18n.t('general.save') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for modifying image -->
				<v-dialog
					v-model="imageDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							<template v-if="item.hero_image">
								{{ $i18n.t('addImage.changeImage') }}
							</template>
							<template v-else>
								{{ $i18n.t('addImage.addImage') }}
							</template>
						</v-card-title>
						<v-divider />
						<v-card-text>
							<v-img
								v-if="item.hero_image"
								aspect-ratio="1.628"
								:src="item.hero_image.url"
								dark
								class="mb-4"
							>
								<v-app-bar
									flat
									color="rgba(0, 0, 0, 0)"
								>
									<v-spacer />
									<v-btn
										color="primary"
										fab
										small
										@click="deleteImageDialogOpen = true"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-app-bar>
							</v-img>
							<SchemaToForm
								ref="imageForm"
								v-model="imageFormData"
								:schema="config.forms.teamImage"
								:disabled="imageSaveLoading"
								:debug="false"
								@valid="imageFormValid = true"
								@invalid="imageFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="imageSaveLoading === true"
								@click="imageDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="imageSaveLoading === true || imageFormValid === false"
								:loading="imageSaveLoading"
								@click="addImage"
							>
								{{ $i18n.t('general.save') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for deleting image -->
				<v-dialog
					v-model="deleteImageDialogOpen"
					max-width="300"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('deleteImage.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							{{ $i18n.t('deleteImage.text') }}
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="imageSaveLoading === true"
								@click="deleteImageDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="imageSaveLoading === true"
								:loading="imageSaveLoading"
								@click="deleteImage"
							>
								{{ $i18n.t('general.delete') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for adding meeting -->
				<v-dialog
					v-model="meetingDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('meetings.addMeeting.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<SchemaToForm
								ref="meetingForm"
								v-model="meetingFormData"
								:schema="config.forms.meeting"
								:disabled="meetingSaveLoading"
								@valid="meetingFormValid = true"
								@invalid="meetingFormValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								:disabled="meetingSaveLoading === true"
								@click="meetingDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-spacer />
							<v-btn
								color="primary"
								text
								:disabled="meetingSaveLoading === true || meetingFormValid === false"
								:loading="meetingSaveLoading"
								@click="saveMeeting"
							>
								{{ $i18n.t('general.save') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for showing all meetings -->
				<v-dialog
					v-model="allMeetingsDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('meetings.allMeetings.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<Spinner v-if="allMeetingsLoading === true" />
							<MeetingList
								v-else
								:items="allMeetings"
								:no-items-text="$i18n.t('meetings.noMeetings')"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="allMeetingsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for standings -->
				<v-dialog
					v-model="standingsDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('standings.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<Spinner v-if="standingsLoading === true" />
							<FilterableList
								v-else
								:items="standings"
								icon-src="_icon"
								icon-color-src="_icon_color"
								subtitle-src="_list_summary"
								title-src="title"
								summary-src="_meetings_persons_total_summary"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="standingsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for announcement -->
				<v-dialog
					v-model="announcementDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<template v-if="announcementSelected && Object.keys(announcementSelected).length">
							<v-card-title>{{ announcementSelected.title }}</v-card-title>
							<v-divider />
							<v-card-text>
								<p class="text-subtitle-2">
									{{ announcementSelected.date }}
								</p>
								<p class="text-subtitle-2">
									{{ announcementSelected.summary }}
								</p>
								<ContentBlocks
									v-if="announcementDialogOpen"
									:items="announcementSelected.content_blocks"
									:spacing="3"
								/>
							</v-card-text>
							<v-divider />
						</template>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="announcementDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for showing all announcements -->
				<v-dialog
					v-model="allAnnouncementsDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('announcements.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<Spinner v-if="allAnnouncementsLoading === true" />
							<AnnouncementList
								v-else
								:items="allAnnouncements"
								@itemClick="openAnnouncementDialog"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="allAnnouncementsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for showing new achievement -->
				<v-dialog
					v-model="newAchievementDialogOpen"
					max-width="300"
					scrollable
				>
					<v-card>
						<v-img
							aspect-ratio="1.628"
							src="@/assets/trophy.png"
							contain
						/>
						<template v-if="newAchievementVisible && newAchievementVisible.title">
							<v-card-title>
								<div
									class="text-center"
									style="width: 100%"
								>
									{{ $i18n.t('newAchievement.title.' + newAchievementVisible.type.name) }}
								</div>
							</v-card-title>
							<v-divider />
							<v-card-text class="text-center">
								<p class="text-subtitle-2">
									{{ newAchievementVisible.title }}
								</p>
								<p
									v-if="newAchievementVisible.summary"
									class="mb-0"
								>
									{{ newAchievementVisible.summary }}
								</p>
							</v-card-text>
							<v-divider />
						</template>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="newAchievementDialogOpen = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for showing member -->
				<v-dialog
					v-model="memberDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<template v-if="memberSelected && Object.keys(memberSelected).length">
							<v-card-title>{{ memberSelected.nickname }}</v-card-title>
							<v-divider />
							<v-card-text>
								<template v-if="memberSelected.achievements.length">
									<template v-for="category of memberSelected.achievements">
										<v-subheader :key="`category-${category.id}`">
											{{ category.title }}
										</v-subheader>
										<FilterableList
											:key="`achievementList-${category.id}`"
											:items="category.items"
											title-src="title"
											summary-src="summary"
											:enable-click="false"
											:enable-search="false"
										/>
									</template>
								</template>
								<v-alert
									v-else
									type="info"
									class="ma-0"
								>
									{{ $i18n.t('members.noAchievements') }}
								</v-alert>
							</v-card-text>
							<v-divider />
						</template>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="memberDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import StatisticsPositionInStandings from '@/components/statistics/StatisticsPositionInStandings'
import StatisticsProgress from '@/components/statistics/StatisticsProgress'
import JSConfetti from 'js-confetti'
import MeetingList from '@/components/MeetingList'
import AnnouncementList from '@/components/AnnouncementList'
const audioPlay = require('audio-play')
const audioLoader = require('audio-loader')

export default {
	name: 'Team',
	components: {
		StatisticsPositionInStandings,
		StatisticsProgress,
		MeetingList,
		AnnouncementList,
	},
	data: () => ({
		loading: false,
		item: {},
		pollInterval: null,
		currentTab: null,
		editDialogOpen: false,
		editFormData: {},
		editFormValid: false,
		editSaveLoading: false,
		imageDialogOpen: false,
		imageFormData: {},
		imageFormValid: false,
		imageSaveLoading: false,
		deleteImageDialogOpen: false,
		meetingDialogOpen: false,
		meetingFormData: {},
		meetingFormValid: false,
		meetingSaveLoading: false,
		allMeetingsDialogOpen: false,
		allMeetingsLoading: false,
		allMeetings: [],
		standingsDialogOpen: false,
		standingsLoading: false,
		standings: [],
		announcementSelected: null,
		announcementDialogOpen: false,
		allAnnouncementsDialogOpen: false,
		allAnnouncementsLoading: false,
		allAnnouncements: [],
		membersLoading: false,
		members: [],
		memberSelected: null,
		memberDialogOpen: false,
		confetti: null,
		newAchievements: [],
		newAchievementVisible: null,
		newAchievementDialogOpen: false,
		achievementSounds: [
			{
				file: require('@/assets/sounds/tsemppiaani1.wav'),
				buffer: null,
			},
			{
				file: require('@/assets/sounds/tsemppiaani2.wav'),
				buffer: null,
			},
			{
				file: require('@/assets/sounds/tsemppiaani3.wav'),
				buffer: null,
			},
			{
				file: require('@/assets/sounds/tsemppiaani4.wav'),
				buffer: null,
			},
			{
				file: require('@/assets/sounds/tsemppiaani5.wav'),
				buffer: null,
			},
		],
	}),
	computed: {
		...mapState([
			'config',
			'user',
		]),
		apiUrl () {
			if (!this.item) return null

			if (this.item._contest_uses_districts === true) {
				return `${this.$route.params.contestName}/${this.$route.params.districtName}/${this.$route.params.teamName}`
			}

			return `${this.$route.params.contestName}/${this.$route.params.teamName}`
		},
	},
	watch: {
		currentTab (val) {
			// Load member list for the team
			if (val === 1) {
				this.membersLoading = true

				this.$api.Contests.doRequest({
					method: 'GET',
					url: this.apiUrl,
					query: {
						action: 'get-members',
					},
				}).on('done', (res) => {
					if (res.body.members) {
						this.members = res.body.members
					}
				}).on('finish', () => {
					this.membersLoading = false
				})
			}
		},

		// When new achievements change, show the next one
		newAchievements: {
			deep: true,
			handler (val) {
				if (!val || !val.length) {
					this.newAchievementVisible = null
					return
				}

				this.newAchievementVisible = val[0]
				this.newAchievementDialogOpen = true
				this.confetti.addConfetti()

				const sound = this.achievementSounds[Math.floor(Math.random() * this.achievementSounds.length)]
				if (sound.buffer) audioPlay(sound.buffer)
			},
		},
		newAchievementDialogOpen (val) {
			if (val === true) return

			// After closing the dialog, remove achievement from the list to trigger change
			// in achievement array and show next achievement.
			setTimeout(() => {
				if (this.newAchievements.length) this.newAchievements.splice(0, 1)
			}, 500)
		},
	},
	async mounted () {
		if (['districtTeam', 'contestTeam'].includes(this.$route.name)) {
			await this.loadItem()
			await this.$nextTick()
			if (!this.item) return

			this.$root.$emit('setHeadline', this.item.title)

			// Add canvas for confetti
			this.confetti = new JSConfetti()

			// Load audio files
			for (const sound of this.achievementSounds) {
				audioLoader(sound.file).then(buffer => {
					sound.buffer = buffer
				})
			}
		}
	},
	beforeDestroy () {
		// Remove confetti canvas
		const confettiCanvas = document.body.querySelector('canvas')
		if (confettiCanvas) confettiCanvas.remove()
	},
	methods: {
		// Load requested item from the store
		loadItem (silent = false) {
			return new Promise((resolve, reject) => {
				if (silent === false) this.loading = true

				this.$api.Contests.doRequest({
					method: 'GET',
					url: this.apiUrl,
				}).on('done', (res) => {
					if (!res.body.team) {
						this.$router.replace({ name: 'error404' })
						return reject()
					}

					this.item = res.body.team
					return resolve(this.item)
				}).on('finish', () => {
					if (silent === false) this.loading = false
				})
			})
		},

		async openEditDialog () {
			this.editFormData = Object.assign({}, this.item)
			this.editDialogOpen = true

			await this.$nextTick()

			this.$refs.editForm.$children[0].resetValidation()
		},

		saveTeam () {
			this.editSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'POST',
				url: this.apiUrl,
				query: {
					action: 'save-details',
				},
				body: this.editFormData,
			}).on('done', (res) => {
				if (res.body.error || !res.body.team) return

				this.item = res.body.team
				this.$root.$emit('setHeadline', this.item.title)
				this.editDialogOpen = false
			}).on('finish', () => {
				this.editSaveLoading = false
			})
		},

		async openImageDialog () {
			this.imageFormData = {}
			this.imageDialogOpen = true

			await this.$nextTick()

			this.$refs.imageForm.$children[0].resetValidation()
		},

		addImage () {
			this.imageSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'POST',
				url: this.apiUrl,
				query: {
					action: 'addImage',
				},
				body: this.imageFormData,
			}).on('done', (res) => {
				if (res.body.error || !res.body.team) return

				this.item = res.body.team
				this.imageFormData = {}
				this.$refs.imageForm.$children[0].resetValidation()
				this.imageDialogOpen = false
			}).on('finish', () => {
				this.imageSaveLoading = false
			})
		},

		deleteImage () {
			this.imageSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'POST',
				url: this.apiUrl,
				query: {
					action: 'deleteImage',
				},
			}).on('done', (res) => {
				if (res.body.error || !res.body.team) return

				this.item = res.body.team
				this.deleteImageDialogOpen = false
				this.imageDialogOpen = false
			}).on('finish', () => {
				this.imageSaveLoading = false
			})
		},

		async openMeetingDialog () {
			this.meetingFormData = {
				method: 'muu-tapa',
			}
			this.meetingDialogOpen = true

			await this.$nextTick()

			this.$refs.meetingForm.$children[0].resetValidation()
		},

		saveMeeting () {
			this.meetingSaveLoading = true

			this.$api.Contests.doRequest({
				method: 'POST',
				url: this.apiUrl,
				query: {
					action: 'addMeeting',
				},
				body: this.meetingFormData,
			}).on('done', (res) => {
				if (res.body.error || !res.body.team) return

				this.item = res.body.team
				this.newAchievements = this.item._new_achievements
				this.$refs.meetingForm.$children[0].resetValidation()
				this.meetingDialogOpen = false
				this.meetingFormData = {}
			}).on('finish', () => {
				this.meetingSaveLoading = false
			})
		},

		openAllMeetingsDialog () {
			this.allMeetingsDialogOpen = true
			this.allMeetingsLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: this.apiUrl,
				query: {
					action: 'get-meetings',
				},
			}).on('done', (res) => {
				if (res.body.meetings) {
					this.allMeetings = res.body.meetings
				}
			}).on('finish', () => {
				this.allMeetingsLoading = false
			})
		},

		openStandingsDialog () {
			this.standingsDialogOpen = true
			this.standingsLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: this.apiUrl,
				query: {
					action: 'get-standings',
				},
			}).on('done', (res) => {
				if (res.body.standings) {
					this.standings = res.body.standings
				}
			}).on('finish', () => {
				this.standingsLoading = false
			})
		},

		openAnnouncementDialog (item) {
			this.announcementSelected = item
			this.announcementDialogOpen = true
		},

		openAllAnnouncementsDialog () {
			this.allAnnouncementsDialogOpen = true
			this.allAnnouncementsLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: this.apiUrl,
				query: {
					action: 'get-announcements',
				},
			}).on('done', (res) => {
				if (res.body.announcements) {
					this.allAnnouncements = res.body.announcements
				}
			}).on('finish', () => {
				this.allAnnouncementsLoading = false
			})
		},

		async openMemberDialog (item = {}) {
			if (!Object.keys(item).length) return

			this.memberSelected = item

			await this.$nextTick()

			this.memberDialogOpen = true
		},
	},
}
</script>
