<i18n>
{
	"en": {
		"noItemsText": "No invoices to show."
	},
	"fi": {
		"noItemsText": "Laskuja ei löytynyt."
	},
	"sv": {
		"noItemsText": "Det finns inga fakturor att visa."
	}
}
</i18n>

<template>
	<FilterableList
		:items="items"
		title-src="_list_title"
		summary-src="_list_summary"
		icon-src="_icon"
		icon-color-src="_icon_color"
		:enable-search="false"
		:no-items-text="$i18n.t('noItemsText')"
		@itemClick="(item) => $router.push({ name: 'invoice', params: { pagename: item.id } })"
	>
		<template #meta="slotProps">
			<v-chip
				v-if="slotProps.item._status && Object.keys(slotProps.item._status).length"
				:color="slotProps.item._status.color"
				outlined
				label
			>
				<v-icon
					v-if="slotProps.item._status.icon"
					left
				>
					{{ slotProps.item._status.icon }}
				</v-icon>

				{{ slotProps.item._status.text }}
			</v-chip>
		</template>
	</FilterableList>
</template>

<script>

export default {
	name: 'InvoiceList',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
	},
}

</script>
