<i18n>
{
	"en": {
		"fields": {
			"cookies": {
				"description": "Allowed cookie types: {categories}.",
				"label": "Allowed cookies"
			},
			"darkMode": {
				"label": "Dark theme"
			},
			"locale": {
				"label": "Language"
			}
		},
		"localeSwitch": {
			"buttonText": "Change language",
			"warn": "When you change application language, all data will be reloaded. Please confirm language change by clicking the button below."
		}
	},
	"fi": {
		"fields": {
			"cookies": {
				"description": "Hyväksytyt evästetyypit: {categories}.",
				"label": "Sallitut evästeet"
			},
			"darkMode": {
				"label": "Tumma väriteema"
			},
			"locale": {
				"label": "Kieli"
			}
		},
		"localeSwitch": {
			"buttonText": "Vaihda kieli",
			"warn": "Sovelluksen kielen vaihtamisen yhteydessä tiedot tulee ladata uudelleen. Vahvista kielen vaihto alla olevalla painikkeella."
		}
	},
	"sv": {
		"fields": {
			"cookies": {
				"description": "Tillåtna cookietyper: {categories}.",
				"label": "Tillåtna cookies"
			},
			"darkMode": {
				"label": "Mörkt läge"
			},
			"locale": {
				"label": "Språk"
			}
		},
		"localeSwitch": {
			"buttonText": "Byt språk",
			"warn": "När du ändrar applikationsspråk kommer all data att laddas om. Vänligen bekräfta språkändringen genom att klicka på knappen nedan."
		}
	}
}
</i18n>

<template>
	<v-container
		v-if="ready"
		class="container--narrow"
	>
		<v-card v-if="ready">
			<v-card-text>
				<v-radio-group
					v-if="config.languages && config.languages.length > 1"
					v-model="locale"
				>
					<template #label>
						<p class="font-weight-bold mb-0">
							{{ $t('fields.locale.label') }}
						</p>
					</template>
					<v-radio
						v-for="(item, i) in config.languages"
						:key="i"
						:label="item.title"
						:value="item.code"
					/>
				</v-radio-group>

				<!--
					Show notification when user is about to change locale.
					Notification will be shown in the selected language.
				-->
				<v-expand-transition>
					<div
						v-show="locale !== settings.locale"
						class="mb-6"
					>
						<v-alert
							type="info"
							dense
						>
							{{ $i18n.t('localeSwitch.warn', locale) }}
						</v-alert>
						<v-btn
							@click="changeLocale"
							color="primary"
							small
						>
							{{ $i18n.t('localeSwitch.buttonText', locale) }}
						</v-btn>
					</div>
				</v-expand-transition>

				<v-switch
					v-model="settings.darkMode"
					:label="$t('fields.darkMode.label')"
				/>

				<!-- Cookie consent -->
				<template v-if="cookieConsentEnabled === true">
					<p class="font-weight-bold mb-3">
						{{ $t('fields.cookies.label') }}
					</p>
					<p>
						{{ $i18n.t('fields.cookies.description', { categories: acceptedCookieCategories }) }}
					</p>
					<v-btn
						color="primary"
						dark
						small
						@click="$root.$emit('openCookieDialog')"
					>
						{{ $i18n.t('general.changeCookieSettings') }}
					</v-btn>
				</template>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Settings',
	data: () => ({
		ready: false,
		locale: null,
		cookieConsentEnabled: process.env.VUE_APP_COOKIE_CONSENT === 'true',
		cookieTypes: [
			'necessary',
			'statistics',
			'marketing',
		],
	}),
	computed: {
		...mapState([
			'config',
			'settings',
		]),
		acceptedCookieCategories () {
			if (!this.settings.acceptedCookies) return

			return this.settings.acceptedCookies.map(val => {
				return this.$i18n.t('cookieTypes.' + val)
			}).join(', ').toLowerCase()
		},
	},
	mounted () {
		// Initially set locale to match current locale
		this.locale = this.settings.locale

		this.ready = true
	},
	methods: {
		// This method will run when user confirms locale change.
		changeLocale () {
			// Update current locale
			this.settings.locale = this.locale

			// Raise event to root component. App.vue will handle data reloading.
			this.$nextTick(() => {
				this.$root.$emit('localeChanged')
			})
		},
	},
}
</script>
