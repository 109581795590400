<i18n>
{
	"en": {
		"noItemsText": "No news to show."
	},
	"fi": {
		"noItemsText": "Uutisia ei löytynyt."
	},
	"sv": {
		"noItemsText": "Det finns inga nyheter att visa."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-container class="container--narrow">
				<FilterableList
					:items="items"
					subtitle-src="date"
					:meta-src="null"
					redirect-url-src="link"
					:no-items-text="$i18n.t('noItemsText')"
					@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
				/>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	computed: {
		...mapState({
			items: state => state.news,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
