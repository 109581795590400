<i18n>
{
	"en": {
		"actions": {
			"logout": "Log out"
		},
		"headings": {
			"application": "Application",
			"social": "Social media"
		}
	},
	"fi": {
		"actions": {
			"logout": "Kirjaudu ulos"
		},
		"headings": {
			"application": "Sovellus",
			"social": "Some"
		}
	},
	"sv": {
		"actions": {
			"logout": "Logga ut"
		},
		"headings": {
			"application": "Applikation",
			"social": "Sociala medier"
		}
	}
}
</i18n>

<template>
	<v-list
		dense
		nav
	>
		<!-- Link groups -->
		<template v-if="config.links.length">
			<v-list-item-group
				v-for="group in config.links"
				:key="group.id"
				color="primary"
			>
				<v-subheader class="text-uppercase">
					{{ group.title }}
				</v-subheader>
				<v-list-item
					v-for="item in group.items"
					:key="item.id"
					:href="item.url"
					target="_blank"
				>
					<v-list-item-icon v-if="item.icon">
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content v-if="item.label">
						{{ item.label }}
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</template>

		<v-list-item-group color="primary">
			<v-subheader
				class="text-uppercase"
			>
				{{ $i18n.t('headings.application') }}
			</v-subheader>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'message')"
				:to="({ name: 'message' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-email
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.message.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'benefitContainer')"
				:to="({ name: 'benefitContainer' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-star
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.benefitContainer.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'invoiceContainer')"
				:to="({ name: 'invoiceContainer' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-receipt
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.invoiceContainer.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'settings')"
				:to="({ name: 'settings' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-cog
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.settings.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="$emit('logout')">
				<v-list-item-icon>
					<v-icon>
						mdi-logout
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('actions.logout') }}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'OffCanvasNav',
	computed: {
		...mapState([
			'config',
		]),
	},
}

</script>

<style lang="scss" scoped>

.mdi-twitter::before {
	content: url('../assets/twitter-x.svg');
	opacity: .5
}

</style>
