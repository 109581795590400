var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.processedItems && _vm.processedItems.length)?[(_vm.enableSearch)?_c('v-sheet',{staticClass:"pt-6 pb-3"},[_c('v-text-field',{attrs:{"label":_vm.searchLabel || _vm.$i18n.t('inputLabel'),"hide-details":true,"prepend-inner-icon":"mdi-magnify","rounded":"","clearable":"","filled":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1):_vm._e(),(_vm.filteredItems.length)?_c('v-list',_vm._b({},'v-list',_vm.listProps,false),[_vm._l((_vm.filteredItems),function(item,index){return [_c('v-list-item',_vm._g({key:item[_vm.keySrc],attrs:{"href":(item[_vm.redirectUrlSrc]) ? item[_vm.redirectUrlSrc] : null,"target":(item[_vm.redirectUrlSrc]) ? '_blank' : null,"disabled":item.disabled}},_vm.enableClick ? { click: () => _vm.itemClick(item) } : {}),[(_vm.avatarSrc && item[_vm.avatarSrc])?_c('v-list-item-avatar',[(!_vm.$scopedSlots.avatar)?[_c('v-img',{attrs:{"alt":"","src":item[_vm.avatarSrc].url}})]:_vm._e(),_vm._t("avatar",null,{"item":item})],2):(item[_vm.iconSrc])?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":(item[_vm.iconColorSrc]) ? item[_vm.iconColorSrc] : 'primary'}},[_vm._v(" "+_vm._s(item[_vm.iconSrc])+" ")])],1):_vm._e(),_c('v-list-item-content',[(!_vm.$scopedSlots.content)?[(item[_vm.subtitleSrc] || !!_vm.$scopedSlots.subtitle === true)?_c('v-list-item-subtitle',{class:[
									_vm.multiline === true ? 'v-list-item__subtitle--multiline' : null
								]},[(!!_vm.$scopedSlots.subtitle === false)?_c('span',{domProps:{"innerHTML":_vm._s(item[_vm.subtitleSrc])}}):_vm._e(),_vm._t("subtitle",null,{"item":item})],2):_vm._e(),(item[_vm.titleSrc] || !!_vm.$scopedSlots.title === true)?_c('v-list-item-title',{class:[
									_vm.multiline === true ? 'v-list-item__title--multiline' : null
								]},[(!!_vm.$scopedSlots.title === false)?_c('span',{domProps:{"innerHTML":_vm._s(item[_vm.titleSrc])}}):_vm._e(),_vm._t("title",null,{"item":item})],2):_vm._e(),(item[_vm.summarySrc] || !!_vm.$scopedSlots.summary === true)?_c('v-list-item-subtitle',{class:[
									_vm.multiline === true ? 'v-list-item__subtitle--multiline' : null
								]},[(!!_vm.$scopedSlots.summary === false)?_c('span',{domProps:{"innerHTML":_vm._s(item[_vm.summarySrc])}}):_vm._e(),_vm._t("summary",null,{"item":item})],2):_vm._e()]:_vm._e(),_vm._t("content",null,{"item":item})],2),(item[_vm.metaSrc] || !!_vm.$scopedSlots.meta === true)?_c('v-list-item-action',[(!_vm.$scopedSlots.meta)?_c('v-list-item-action-text',{domProps:{"innerHTML":_vm._s(item[_vm.metaSrc])}}):_vm._e(),_vm._t("meta",null,{"item":item})],2):_vm._e()],1),(index != _vm.filteredItems.length - 1)?_c('v-divider',{key:('divider-' + index),attrs:{"inset":(item.icon && item.icon.length > 0)}}):_vm._e()]})],2):_c('p',{staticClass:"pa-6"},[_vm._v(" "+_vm._s(_vm.noResultsText || _vm.$i18n.t('noResultsText'))+" ")])]:_c('v-alert',{staticClass:"mt-3",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.noItemsText || _vm.$i18n.t('noItemsText'))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }