<template>
	<div ref="wrap">
		<div
			v-for="(item, index) in items"
			:key="item.id"
			:class="[
				index > 0 ? `pt-${spacing}` : null,
				index < items.length -1 ? `pb-${spacing}` : null,
			]"
		>
			<div
				v-if="item.type === 'text_content_block' && item.content"
				class="rte"
				v-html="item.content"
			/>
			<template v-else-if="item.type === 'image_block' && item.image">
				<v-img
					:src="item.image.url"
					:alt="item.image.alt"
				/>
				<p
					v-if="item.title"
					class="text-body-2 text-center mt-3"
				>
					{{ item.title }}
				</p>
			</template>
			<template v-else-if="item.type === 'video_block' && item.video_link">
				<CookieContent type="marketing">
					<div v-html="item.video_link" />
				</CookieContent>
			</template>
			<template v-else-if="item.type === 'files_block' && item.files.length">
				<FilterableList
					:items="item.files"
					:enable-search="false"
					title-src="list_title"
					summary-src="list_summary"
					redirect-url-src="url"
				/>
			</template>
			<template v-else-if="item.type === 'buttons_block' && item.buttons.length">
				<div class="buttonContainer">
					<template v-for="button of item.buttons">
						<v-btn
							v-if="button.button_link_type && button.button_link_type.value === 'url' && button.link"
							:key="`${button.id}-url`"
							color="primary"
							class="buttonContainer__item"
							:href="button.link"
							target="_blank"
						>
							{{ button.title }}
						</v-btn>
						<v-btn
							v-else-if="button.button_link_type && button.button_link_type.value === 'file' && button.file"
							:key="`${button.id}-file`"
							color="primary"
							class="buttonContainer__item"
							:href="button.file.url"
						>
							{{ button.title }}
						</v-btn>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ContentBlocks',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
		spacing: {
			type: [Number],
			required: false,
			default () {
				return 6
			},
		},
	},
	mounted () {
		this.init()
	},
	updated () {
		this.init()
	},
	methods: {
		init () {
			if (typeof this.$refs.wrap == 'undefined') return

			// Force links to open in new window
			const links = this.$refs.wrap.querySelectorAll('.rte a')

			for (const item of links) {
				const href = item.getAttribute('href')

				if (!href) continue
				if (href.startsWith('tel:') || href.startsWith('mailto:') || href.startsWith('#')) continue

				item.setAttribute('target', '_blank')
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.buttonContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -.5em;

	&__item {
		margin: .5em;
	}
}

</style>
