import { render, staticRenderFns } from "./StatisticsPositionInStandings.vue?vue&type=template&id=db54d412&scoped=true&"
import script from "./StatisticsPositionInStandings.vue?vue&type=script&lang=js&"
export * from "./StatisticsPositionInStandings.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsPositionInStandings.vue?vue&type=style&index=0&id=db54d412&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db54d412",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VAlert,VCard,VCardActions,VCardText,VCardTitle,VDivider})
