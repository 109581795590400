<i18n>
{
	"fi": {
		"downloadStatistics": {
			"title": "Lataa tilasto"
		},
		"teams": {
			"title": "Piirin joukkueet",
			"noItemsText": "Piirillä ei ole joukkueita"
		},
		"standings": {
			"title": "Sarjataulukko"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="loading === true"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'district' && item && Object.keys(item).length">
				<!-- Details -->
				<v-container
					v-if="(item._details && item._details.length) || (item._download_links && item._download_links.length)"
					class="container--narrow"
				>
					<v-card>
						<v-card-text v-if="item._details && item._details.length">
							<FilterableList
								:items="item._details"
								subtitle-src="label"
								title-src="value"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<template v-if="item._download_links && item._download_links.length">
							<v-divider />
							<v-card-actions>
								<v-btn
									text
									color="primary"
									@click="statisticsDialogOpen = true"
								>
									<v-icon left>
										mdi-download
									</v-icon>
									{{ $i18n.t('downloadStatistics.title') }}
								</v-btn>
							</v-card-actions>
						</template>
					</v-card>
				</v-container>

				<!-- Statistics -->
				<v-container
					v-if="item._statistics && Object.keys(item._statistics).length"
					class="container--narrow"
				>
					<v-row>
						<v-col
							v-if="item._statistics.top_teams"
							cols="12"
							sm="6"
						>
							<StatisticsTeams
								:title="item._statistics.top_teams.title"
								:error="item._statistics.top_teams.error"
								:info="item._statistics.top_teams.info"
								:teams="item._statistics.top_teams.items"
							>
								<template
									v-if="item._statistics.top_teams.items"
									#actions
								>
									<v-btn
										text
										color="primary"
										@click="openStandingsDialog"
									>
										<v-icon left>
											mdi-chart-bar
										</v-icon>
										{{ $i18n.t('standings.title') }}
									</v-btn>
								</template>
							</StatisticsTeams>
						</v-col>
						<v-col
							v-if="item._statistics.meetings_persons_total"
							cols="12"
							sm="6"
						>
							<StatisticsProgress
								:title="item._statistics.meetings_persons_total.title"
								:error="item._statistics.meetings_persons_total.error"
								:info="item._statistics.meetings_persons_total.info"
								:progress="item._statistics.meetings_persons_total.progress"
								:details="item._statistics.meetings_persons_total.details"
							/>
						</v-col>
					</v-row>
				</v-container>

				<!-- Teams of the district -->
				<v-container
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('teams.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<FilterableList
								:items="item.teams"
								:enable-search="item.teams.length > 10"
								icon-src="_icon"
								title-src="title"
								summary-src="_summary"
								:no-items-text="$i18n.t('teams.noItemsText')"
								@itemClick="item => $router.push({ name: 'districtTeam', params: { teamName: item.name } })"
							/>
						</v-card-text>
					</v-card>
				</v-container>

				<!-- Dialog for downloading statistics -->
				<v-dialog
					v-model="statisticsDialogOpen"
					max-width="400"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('downloadStatistics.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<v-list>
								<v-list-item
									v-for="link of item._download_links"
									:key="link.name"
									:href="link.url"
									download
								>
									<v-list-item-icon v-if="link.icon">
										<v-icon color="primary">
											{{ link.icon }}
										</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-if="link.title">
											{{ link.title }}
										</v-list-item-title>
										<v-list-item-subtitle v-if="link.description">
											{{ link.description }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="statisticsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog for standings -->
				<v-dialog
					v-model="standingsDialogOpen"
					max-width="500"
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('standings.title') }}
						</v-card-title>
						<v-divider />
						<v-card-text>
							<Spinner v-if="standingsLoading === true" />
							<FilterableList
								v-else
								:items="standings"
								icon-src="_icon"
								icon-color-src="_icon_color"
								subtitle-src="_list_summary"
								title-src="title"
								summary-src="_meetings_persons_total_summary"
								:enable-click="false"
								:enable-search="false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="standingsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import StatisticsTeams from '@/components/statistics/StatisticsTeams'
import StatisticsProgress from '@/components/statistics/StatisticsProgress'

export default {
	name: 'District',
	components: {
		StatisticsTeams,
		StatisticsProgress,
	},
	data: () => ({
		loading: false,
		item: {},
		standingsDialogOpen: false,
		standingsLoading: false,
		standings: [],
		statisticsDialogOpen: false,
	}),
	computed: {
		...mapState({
			user: state => state.user,
		}),
	},
	async mounted () {
		if (this.$route.name === 'district') {
			await this.loadItem()
			await this.$nextTick()

			if (!this.item) return

			this.$root.$emit('setHeadline', this.item.title)
		}
	},
	methods: {
		// Load requested item from the store
		loadItem () {
			return new Promise((resolve, reject) => {
				this.loading = true

				this.$api.Contests.doRequest({
					method: 'GET',
					url: this.$route.params.contestName + '/' + this.$route.params.districtName,
				}).on('done', (res) => {
					if (!res.body.district) {
						this.$router.replace({ name: 'error404' })
						return reject()
					}

					this.item = res.body.district
					return resolve(this.item)
				}).on('finish', () => {
					this.loading = false
				})
			})
		},

		openStandingsDialog () {
			this.standingsDialogOpen = true
			this.standingsLoading = true

			this.$api.Contests.doRequest({
				method: 'GET',
				url: this.$route.params.contestName + '/' + this.$route.params.districtName,
				query: {
					action: 'get-standings',
				},
			}).on('done', (res) => {
				if (res.body.standings) {
					this.standings = res.body.standings
				}
			}).on('finish', () => {
				this.standingsLoading = false
			})
		},
	},
}
</script>
