import i18n from '@/i18n'

export default {
	required: v => (!!v && !!v.length) || i18n.t('validationErrors.required'),
	requiredFile: v => (!!v && typeof v === 'object') || i18n.t('validationErrors.required'),
	email: v => /.+@.+/.test(v) || i18n.t('validationErrors.email'),
	teamName: v => (!v || String(v).length <= 50) || i18n.t('validationErrors.teamName'),
	meetingAmount: v => (!v || parseInt(v) <= 100) || i18n.t('validationErrors.meetingAmount'),
	meetingDescription: v => (!v || String(v).length <= 200) || i18n.t('validationErrors.meetingDescription'),
}
