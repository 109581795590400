<template>
	<FilterableList
		:items="items"
		icon-src="_icon"
		subtitle-src="date"
		title-src="title"
		summary-src="summary"
		:meta-src="null"
		:enable-search="false"
		:no-items-text="noItemsText"
		@itemClick="item=> $emit('itemClick', item)"
	>
		<template #subtitle="slotProps">
			{{ slotProps.item.date }}
			<v-chip
				v-if="slotProps.item._source"
				class="ml-3"
				color="primary"
				x-small
			>
				{{ slotProps.item._source }}
			</v-chip>
		</template>
	</FilterableList>
</template>

<script>

export default {
	name: 'AnnouncementList',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},

		noItemsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},
	},
}

</script>
